<template>
  <div v-if="paymentPref">
    <div class="bg-white --w-bold text-center py-6">
      <h3 v-if="message" class="mb-3 c--d-red">
        {{ message }}
      </h3>
      <h1 class="text-heading --prm-light">
        {{ $t("memberInfo.currentPaymentMethod") }}
      </h1>
    </div>
    <div class="form-container px-5 pt-7 pb-15">
      <!-- START Payment-list -->
      <PaymentMethodItem
        @change="(v) => update('accommodationFeePayment', v)"
        :title="$t('memberInfo.accommodationFee')"
        :paymentMethod="paymentPref.accommodationFeePayment"
      />
      <PaymentMethodItem
        @change="(v) => update('facilityFeePayment', v)"
        :title="$t('memberInfo.facilityFee')"
        :paymentMethod="paymentPref.facilityFeePayment"
      />
      <PaymentMethodItem
        @change="(v) => update('setupFeePayment', v)"
        :title="$t('memberInfo.setUpFee')"
        :paymentMethod="paymentPref.setupFeePayment"
      />
      <PaymentMethodItem
        @change="(v) => update('optionalCleaningPayment', v)"
        :title="$t('memberInfo.optionalCleaningFee')"
        :paymentMethod="paymentPref.optionalCleaningPayment"
      />
      <PaymentMethodItem
        @change="(v) => update('taxPayment', v)"
        :title="$t('memberInfo.inlandRevenueAndAccommodationTax')"
        :paymentMethod="paymentPref.taxPayment"
      />
      <PaymentMethodItem
        @change="(v) => update('otherServicePayment', v)"
        :title="$t('memberInfo.paidService')"
        :paymentMethod="paymentPref.otherServicePayment"
      />
      <!-- END Payment-list -->
    </div>
  </div>
</template>

<script>
import PaymentMethodItem from './PaymentMethodItem.vue'

export default {
  name: 'MemberInfoPaymentMethod',
  components: {
    PaymentMethodItem
  },

  async mounted () {
    await this.reload()
  },
  methods: {
    async update (key, value) {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('updatePrefPaymentMethod', {
            [key]: value
          })
          await this.reload()
        })
      })
    },
    async reload () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('getPrefPaymentMethod')
        })
      })
    }
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    paymentPref () {
      return this.$store.state.client.paymentPref
    },
    message () {
      if (this.$route.query['please-set']) {
        return 'Vacation会員ページをご利用いただくためにお支払い方法をご指定ください。'
      }
      return ''
    }
  }
}
</script>
