<template>
  <div class="pa-5 bg-w-silver mb-3">
    <div class="text-title --w-bold">
      {{ title }}
    </div>
    <v-divider class="bg-gray--light my-2"/>
    <div class="d-flex justify-space-between align-center mt-4">
      <span class="text-title">
        {{ translated[0] }}
        <template v-if="translated[1]">
          <br/>{{ translated[1] }}
        </template>
      </span>
      <v-btn
        elevation="0"
        @click="isOpenDialog = true"
        rounded
        height="57"
        width="154"
        outlined
        class="bg-white text-title --prm-light width-x-small"
        :disabled="!$isMainUser()"
      >
        {{ $t("buttons.change") }}
      </v-btn>
    </div>

    <!-- START Dialog -->
    <CommonModal :visible="isOpenDialog" @close="isOpenDialog = false">
      <h3 class="text-title text-center --prm-light --w-bold line-height-1">
        {{ title }}
      </h3>

      <div class="px-8 py-5">
        <v-radio-group
          :value="paymentMethod"
          @change="handleChange"
          hide-details
          class="mt-0"
        >
          <v-radio value="Onsite" :ripple="false" class="pa-5">
            <template slot="label">
              <div class="text-title--w-bold --c-dark">
                {{ $t("memberInfo.payAtTheFacility") }}
              </div>
            </template>
          </v-radio>
          <v-radio value="RegisteredCC" :ripple="false" class="pa-5">
            <template slot="label">
              <div class="text-title--w-bold --c-dark">
                {{ $t("common.registered") }}
                {{ $t("common.creditCard") }}
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </CommonModal>
  </div>
</template>

<script>
import CommonModal from '../../../components/Modal/Common'

export default {
  name: 'PaymentMethodItem',
  components: { CommonModal },
  props: {
    title: String,
    paymentMethod: String
  },
  data () {
    return {
      isOpenDialog: false
    }
  },
  computed: {
    translated () {
      if (!this.paymentMethod) return ['--未選択--']
      return this.paymentMethod === 'RegisteredCC'
        ? [this.$t('common.registered'), this.$t('common.creditCard')]
        : [this.$t('memberInfo.payAtTheFacility')]
    }
  },
  methods: {
    handleChange (selected) {
      this.$emit('change', selected)
      this.isOpenDialog = false
    }
  }
}
</script>
